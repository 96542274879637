import styled from "styled-components"

export const AboutPageStyles = styled.div`
  #about-info-container {
    margin: 30px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;

    @media all and (-ms-high-contrast: none) {
      display: -ms-flexbox;
    }

    #about-text {
      width: 80%;
      line-height: 30px;
      justify-self: right;

      @media (max-width: 1250px) {
        width: 100%;
        justify-self: center;
      }
    }

    #about-image-container {
      width: 80%;
      background-color: #f2f3f4;
      border: solid 1px lightgray;

      #about-image {
        padding: 30px;
        img {
          width: 100%;
          position: relative;
          height: 100%;
          object-fit: cover;
        }

        iframe {
          border: none;
          margin-top: 2rem;
          width: 100%;
          height: 300px;
        }
      }

      #about-image-text {
        position: relative;
        padding: 0 30px;
      }
      @media (max-width: 1250px) {
        width: 100%;
      }
    }
    @media (max-width: 1250px) {
      grid-template-columns: 100%;
    }

    #address-container {
      margin-top: 2.5rem;

      justify-content: space-around;

      h3 {
        margin: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      #google-map {
        iframe {
          width: 500px;
          height: 250px;

          @media (max-width: 510px) {
            width: 355px;
          }
        }
      }
    }
  }
`
