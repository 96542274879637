import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeadings from "../components/PageHeadings"
import { AboutPageStyles } from "../components/styles/AboutPageStyles"
import AboutImage from "../images/RPS-Front-Edit.jpg"
import DemoSection from "../components/DemoSection"

import SEO from "../components/seo"
import { Helmet } from "react-helmet"

const AboutPage = () => {
  return (
    <>
      <SEO title="About Us" />
      <Helmet>
        {/* <title>Kodiak Cleaning Equipment</title> */}
        <description></description>
      </Helmet>
      <div>
        <MainLayout>
          <PageHeadings
            pageHeading="About"
            // description="cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan polaroid food truck Banksy fingerstache Thundercats art party cred chia next level Pinterest slow-carb before  Life disrupt"
          />
          <AboutPageStyles>
            <div id="about-info-container">
              <div id="about-text">
                <h1>A Bit About Us</h1>
                <p>
                  Kodiak manufactures and designs its industrial equipment in
                  the dairyland of Wisconsin, USA. We design durable and
                  effective equipment while keeping things simple, even for your
                  budget. At Kodiak, we strive to receive your order and produce
                  your custom piece of equipment in a matter of days so it can
                  get to your facility quick. We can successfully achieve quick
                  deliveries by having massive sub-assemblies ready and by
                  producing many of the components in-house. We care about
                  delivering the right piece of equipment to fit your unique
                  applications needs, and we want you to have it for years. We
                  don’t want you to call us back in a year and need a new one,
                  we designed a machine that can be easily maintained while
                  being durable enough for daily hardships while not breaking
                  down on you.
                </p>
                <div id="address-container">
                  <div>
                    <h3>Our Location:</h3>
                    <ul>
                      <li>1711 South Street</li>
                      <li>Racine, WI</li>
                      <li>USA</li>
                      <li>53404</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="about-image-container">
                <div id="about-image">
                  <img src={AboutImage} alt="" />
                  <div id="google-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2929.428180666852!2d-87.80510068414651!3d42.758148979162165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880543dbb04d00e7%3A0x4bd6f3adf71e7dd5!2s1711%20South%20St%2C%20Racine%2C%20WI%2053404!5e0!3m2!1sen!2sus!4v1578672964561!5m2!1sen!2sus"
                      // style={{ width: "500px", height: "250px" }}
                      // width="600"
                      // height="450"
                      // frameborder="0"
                      // style="border:0;"
                      // allowfullscreen=""
                    ></iframe>
                  </div>
                </div>
                <div id="about-image-text">
                  {/* <p>
                    aesthetic VHS cornhole cardigan hoodie PBR gluten-free
                    mustache roof party actually normcore irony meggings
                    scenester keytar mlkshk master cleanse single-origin coffee
                    plaid disrupt Wes
                  </p> */}
                </div>
              </div>
            </div>
          </AboutPageStyles>
          <DemoSection />
        </MainLayout>
      </div>
    </>
  )
}

export default AboutPage
